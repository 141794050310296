<template>
    <section class="iceberg">
        <h2>Programtestet</h2>
        <article class="test">
            <transition name="fade">
                <overlay v-if="showOverlay" :program="activeProgram" />
            </transition>
            <aside v-for="(prgm, index) in iceberg" :key="index" @click="showProgram(prgm)">
                <p class="program">{{ prgm.title }}</p>
                <p class="dots"></p>
                <p class="program-value">{{prgm.value}}p</p>
            </aside>
        </article>
    </section>
</template>

<script>

import overlay from '@/components/Overlay';

export default {
    name: 'profileiceberg',
    components: {
        overlay
    },
    data(){
        return {
            threshold: 6,
            showOverlay: false,
            activeProgram: null
        }
    },
    computed: {
        iceberg(){
            return this.$store.getters.profile.iceberg;
        }
    },
    methods: {
        showProgram(program){
            this.activeProgram = program;
            this.showOverlay = !this.showOverlay;
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.iceberg {
    
    .test {
        background: rgba($color: $darkgrey, $alpha: .1);
        padding: .5rem;
        margin: 0 0 1rem 0;
        border-radius: $radius;

        aside {
            display: flex;

            .program {
                font-size: .8rem;
                font-weight: 700;
                margin: .25rem 0;
            }

            .dots {
                border-bottom: 1px dotted rgba($color: #000000, $alpha: .2);
                flex: 1;
                margin: auto .5rem;
            }

            .program-value {
                margin-left: auto;
            }
        }
    }
}
</style>
