<template>
    <section id="overlay">
        <article class="modal">
            <h1>{{ program.title }}</h1>
            <p>{{ program.desc }}</p>
            <a href="#" class="btn" @click="close">Ok!</a>
        </article>
    </section>
</template>

<script>
export default {
    name: 'overlay',
    props: ['program'],
    methods: {
        close(){
            this.$parent.showOverlay = false;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/variables';

#overlay {
    position: fixed;
    @extend %center;
    padding: 2rem;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: $darkgrey, $alpha: .9);

    .modal {
        background: white;
        padding: 1rem;
        border-radius: $radius;
        box-shadow: 0 0 2rem rgba($color: $darkgrey, $alpha: .2);
    }
}
</style>
