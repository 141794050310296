<template>
  <main id="iceberg-results">
    <section class="content">
      <section class="intro">
          <h1 class="main">Resultat</h1>
      </section>
      <article class="floating">
        <h1>Ditt resultat på Programtestet</h1>
        <p>Nedan visas de program som enligt detta test passar dig bäst.</p>
        <profileiceberg />
        <aside class="inline-menu">
          <router-link to="/test">Fler test!</router-link>
          <router-link to="/studieprofil">Till min studieprofil</router-link>
        </aside>
      </article>
    </section>
  </main>
</template>

<script>
import profileiceberg from '@/components/profiles/IcebergProfile';

export default {
  name: 'gardnerresults',
  data(){
      return {
          threshold: 7
      }
  },
  components: {
      profileiceberg
  },
  computed: {
      programs(){
          return this.$store.state.profile.iceberg
      }
  }
}
</script>

<style lang="scss">
@import '../../../scss/variables';

#iceberg-results {
    
    .content {

        .intro {
            position: relative;

            aside {
                background: black;
                position: absolute;
                top: 1rem;
                left: 1rem;
                padding: .25rem .5rem;
                border-radius: $radius;
            }
        }

        .floating {

            .desc {

                h2 {
                    margin: 1.5rem 0 .5rem 0;
                }
            }
            
            .inline-menu {
                    margin: 2rem 0 0 0;
            }
        }
    }
}

</style>

